import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/scss/index.scss";
import PropTypes from "prop-types";
import RichText from "../components/RichText";

const PravidlaWebview = ({ data }) => {
	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex, follow" />
				<meta property="og:locale" content="cs_CZ" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="AppParade" />
				<meta
					property="og:description"
					content="Tuhle AppParade máte ve svých rukou. Dalším zvoleným tématem květnové AppParade je zajímavé využití push notifikací a dalších nástrojů, které motivují uživatele aplikaci znovu otevřít. Jak řeší re-engagement vaše appka? Máte recept? Přihlaste svou aplikaci a ukažte ji světu."
				/>
				<meta property="og:url" content={data.site.siteMetadata.siteUrl} />
				<meta property="fb:app_id" content="993900171000614" />
				{data.og_images.edges
					.map(edge => edge.node)
					.map(image => (
						<meta
							key={image.publicURL}
							property="og:image"
							content={`${data.site.siteMetadata.siteUrl}${image.publicURL}`}
						/>
					))}
			</Helmet>

			<div id="rules">
				<RichText content={data.rules.html} />
				<hr />
				<div className="container-heading">
					<h2 className="slash small-headline">
						Souhlas se zpracováním osobních údajů
					</h2>
				</div>
				<hr />
				<RichText content={data.oou.html} />
			</div>
		</>
	);
};

export default PravidlaWebview;

PravidlaWebview.propTypes = {
	data: PropTypes.shape({
		rules: PropTypes.shape({
			html: PropTypes.string.isRequired
		}).isRequired,
		oou: PropTypes.shape({
			html: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
};

export const rulesQuery = graphql`
	{
		oou: markdownRemark(frontmatter: { title: { eq: "oou" } }) {
			html
		}
		rules: markdownRemark(frontmatter: { title: { eq: "rules" } }) {
			html
		}
		og_images: allFile(
			filter: {
				relativePath: {
					in: ["33-1.png", "33-2.png", "33-3.png", "33-4.jpg", "33-5.png"]
				}
			}
		) {
			edges {
				node {
					publicURL
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
